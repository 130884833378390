import { NavLink as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import * as Scroll from "react-scroll";

function Navbar(props){
    const navitemStyle = {
        marginRight: "24px",
        color: "#efe7d0",
        textDecoration: "none"
    }

    const path = useLocation().pathname;
    const location = path.split("/")[1];
    const navigate = useNavigate();
    const scroller = Scroll.scroller;

    const goToPageAndScroll = async (selector) => {
        await navigate("/");
        await scroller.scrollTo(selector, {
        duration: 500,
        smooth: true,
        offset: -75,
        spy: true
        });
    };

    return (
        <nav>
            <ul style={{display: "flex", flexDirection: "row", margin: `0px`}}>
                {location !== "collection" && location !== "content" ? (
                    <>
                        <li>
                            {" "}
                            <ScrollLink to="home" smooth={true} duration={100} style={navitemStyle}>
                            Home
                            </ScrollLink>
                        </li>
                        <li>
                            <RouterLink to="/collection" style={navitemStyle}>RamenIndex</RouterLink>
                        </li>
                        <li>
                            <ScrollLink to="contact" smooth={true} duration={100} style={navitemStyle}>
                                Contact
                            </ScrollLink>
                        </li>
                    </>
                ) : (
                    <>
                        <li>
                            {" "}
                            <div onClick={() => goToPageAndScroll("home")} style={navitemStyle}>Home</div>
                        </li>
                        <li>
                            <RouterLink to="/collection" style={navitemStyle}>RamenIndex</RouterLink>
                        </li>
                        <li>
                            <div onClick={() => goToPageAndScroll("contact")} style={navitemStyle}>Contact</div>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    );
}

export default Navbar