import { useParams } from 'react-router-dom';
import { ramenData } from "./Database"
import IconArray from "./IconArray"
import MapAPI from "./Map"
import Manga from "./Manga"

function Contentpage(props){
    const { contentId } = useParams();

    const mainImage = {
        backgroundImage: `url(${"/images/" + ramenData[contentId].primaryImg + ".jpg"})`,
        backgroundPosition: `center`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
        height: `100%`,
        aspectRatio: `1/1`,
        border: `solid 4px #0a3f2e`,
    }

    const highlightTextStyle = {
        marginLeft: `0px`, 
        backgroundColor: `#0a3f2e`,
        maxWidth: `100%`
    }

    const mainTextStyle = {
        height: `calc(100% - 40px)`, 
        maxWidth: `100%`,
        marginLeft: `0px`,
        marginTop: `16px`,
        padding: `16px`,
        border: `solid 4px #0a3f2e`,
        backgroundColor: `#efe7d0`
    }

    return (
        <div className="container-fluid content" style={{height: `100%`}}>
            <div className="row">
                <h1 className="daruma-text">{ramenData[contentId].dishName}</h1>
            </div>
            <div className="row">
                <div className="col-lg-8 col-sm-12">
                    <h5>{ramenData[contentId].shopName}, {ramenData[contentId].city}, {ramenData[contentId].country}</h5>
                </div>
                <div className="col-lg-4 col-sm-12 date">
                    <h5>{ramenData[contentId].visitDate}</h5>
                </div>
            </div>

            <div className="row" style={{marginTop: `32px`}}>
                <div className="col-lg-8 col-sm-12 pad-80right">
                    <div className="row" style={highlightTextStyle}>
                        <p style={{color:`#efe7d0`, margin:`0px`}}><b>{ramenData[contentId].shortDesc}</b></p>
                    </div>
                    <div className="row" style={mainTextStyle}>
                        <p style={{backgroundColor: `#efe7d0`, height: `fit-content`, margin: `0px`}}>{ramenData[contentId].longDesc}</p>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 content-image" style={mainImage}></div>
            </div>

            <div className="row" style={{marginTop: `32px`}}>
                <div className="col-8 pad-80right">
                    <h5>Toppings</h5>
                </div>
                <div className="col-4">
                    <h5>Noodle  X  Base</h5>
                </div>
            </div>
            <div className="row" style={{marginTop: `8px`}}>
                <div className="col-8 pad-80right">
                    <IconArray content={ramenData[contentId].toppings} />
                </div>
                <div className="col-4">
                    <IconArray content={[ramenData[contentId].ramenNoodle, ramenData[contentId].ramenBase]} />
                </div>
            </div>

            <div className="row" style={{marginTop: `32px`}}>
                <div className="col-lg-6 col-sm-12 pad-80right">
                    <h5>Location</h5>
                </div>
                <div className="col-lg-6 col-sm-12"></div>
            </div>
            <div className="row" style={{marginTop: `8px`}}>
                <div className="col-lg-6 col-sm-12 pad-80right">
                    <MapAPI center={ramenData[contentId].location} source={ramenData[contentId].mapURL} />
                </div>
                <div className="col-lg-6 col-sm-12" style={{padding: `0px`}}>
                    <Manga src={ramenData[contentId].secondaryImg} />
                </div>
            </div>
        </div>
    )
}

export default Contentpage