function Manga(props){
    if (props.src){
        const mangaImage = {
            backgroundImage: `url(${"/images/" + props.src + ".jpg"})`,
            backgroundPosition: `center`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `cover`,
            aspectRatio: `1/1`,
            border: `solid 4px #0a3f2e`,
        }

        return(
            <div className="manga-frame" style={mangaImage} alt="from Ramen Daizuki Koizumi-san" />
        )
    }   
}

export default Manga