import '../table.css';

function Table(){
    return(
        <table className="custom-table">
            <tbody>
                <tr>
                    <td className="wider-cell daruma-text" rowSpan="2" style={{textAlign: `right`}}>Ramen Noodles</td>
                    <td className='icon-cell'><img className='table-img' src='/icons/straight.svg' alt='straight noodles' /></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/wavy.svg' alt='wavy noodles'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/curly.svg' alt='curly noodles '/></td>
                    <td className='icon-cell'></td>
                    <td className='icon-cell'></td>
                    <td className='icon-cell'></td>
                </tr>
                <tr>
                    <td className='text-cell' style={{borderRight: `none`}}>Straight</td>
                    <td className='text-cell'>Wavy</td>
                    <td className='text-cell'>Curly</td>
                    <td className='text-cell'></td>
                    <td className='text-cell'></td>
                    <td className='text-cell'></td>
                </tr>
                <tr>
                    <td className="wider-cell daruma-text" rowSpan="2" style={{textAlign: `right`}}>Ramen Broth</td>
                    <td className='icon-cell'><img className='table-img' src='/icons/shoyu.svg' alt='shoyu'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/miso.svg' alt='miso' /></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/tonkotsu.svg' alt='tonkotsu'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/shio.svg' alt='shio'/></td>
                    <td className='icon-cell'></td>
                    <td className='icon-cell'></td>
                </tr>
                <tr>
                    <td className='text-cell' style={{borderRight: `none`}}>Shoyu</td>
                    <td className='text-cell'>Miso</td>
                    <td className='text-cell'>Tonkotsu</td>
                    <td className='text-cell'>Shio</td>
                    <td className='text-cell'></td>
                    <td className='text-cell'></td>
                </tr>
                <tr>
                    <td className="wider-cell daruma-text" rowSpan="2" style={{textAlign: `right`}}>Tare</td>
                    <td className='icon-cell'><img className='table-img' src='/icons/shoyu.svg' alt='shoyu'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/miso.svg' alt='miso'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/shio.svg' alt='shio'/></td>
                    <td className='icon-cell'></td>
                    <td className='icon-cell'></td>
                    <td className='icon-cell'></td>
                </tr>
                <tr>
                    <td className='text-cell' style={{borderRight: `none`}}>Shoyu</td>
                    <td className='text-cell'>Miso</td>
                    <td className='text-cell'>Shio</td>
                    <td className='text-cell'></td>
                    <td className='text-cell'></td>
                    <td className='text-cell'></td>
                </tr>
                <tr>
                    <td className="wider-cell daruma-text" rowSpan="2" style={{textAlign: `right`}}>Abura</td>
                    <td className='icon-cell'><img className='table-img' src='/icons/tonkotsu.svg' alt='animal fats'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/vegetable.svg' alt='vegetable fats'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/garlic.svg' alt='mayu'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/chili.svg' alt='rayu'/></td>
                    <td className='icon-cell'></td>
                    <td className='icon-cell'></td>
                </tr>
                <tr>
                    <td className='text-cell' style={{borderRight: `none`}}>Animal Fats</td>
                    <td className='text-cell'>Vegetable Oils</td>
                    <td className='text-cell'>Mayu</td>
                    <td className='text-cell'>Rayu</td>
                    <td className='text-cell'></td>
                    <td className='text-cell'></td>
                </tr>
                <tr>
                    <td className="wider-cell daruma-text" rowSpan="2" style={{textAlign: `right`}}>Toppings</td>
                    <td className='icon-cell'><img className='table-img' src='/icons/egg.svg' alt='ajitama'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/chashu.svg' alt='chashu'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/spring.svg' alt='scallion'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/naruto.svg' alt='naruto'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/fungus.svg' alt='menma'/></td>
                    <td className='icon-cell'><img className='table-img' src='/icons/fat.svg' alt='sesame'/></td>
                </tr>
                <tr>
                    <td className='text-cell' style={{borderRight: `none`}}>Ajitama</td>
                    <td className='text-cell'>Chashu</td>
                    <td className='text-cell'>Scallion</td>
                    <td className='text-cell'>Naruto</td>
                    <td className='text-cell'>Black Fungus</td>
                    <td className='text-cell'>Sesame</td>
                </tr>
            </tbody>
        </table>
    )
}

export default Table