import { Routes, Route } from "react-router-dom";
import Deepbackground from './js/Deepbackground.js'
import Navbar from './js/Navbar.js';
import './App.css';
import Homepage from "./js/Homepage.js";
import Contentpage from "./js/Contentpage.js";
import ContentIndex from "./js/ContentIndex.js";
import ScrollToTop from "./js/ScrollToTop.js";

const App = () => {
  return (
    <div className='App'>
      <Navbar />
      <Deepbackground />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/content/:contentId" element={<Contentpage />}></Route>
        <Route path="/collection" element={<ContentIndex />}></Route>
      </Routes>
    </div>
  );
}

export default App;
