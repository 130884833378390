function IconArray(props){
    const content = props.content

    return (
        <div className="row justify-content-start ingredient-row">
            {content.map((item, index) => (
                <div className="d-flex flex-column align-items-start ingredient-container" key={index} style={{width: `fit-content`}}>
                    <img className="ingredient" src={`/icons/${item}.svg`} alt={`${item}`}/>
                </div>
            ))}
        </div>
    )
}

export default IconArray