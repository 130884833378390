import Section from "./Section"

function Homepage(){

    return (
        <div className="parent">
            <Section id="top" bgimage={"/images/bg_top.png"} sequence="0" />
            <Section bgimage={"/images/bg_mid.png"} sequence="1" />
            <Section bgimage={"/images/bg_mid.png"} sequence="2" />
            <Section id="contact" bgimage={"/images/bg_bottom.png"} sequence="3" />
        </div>
    )
}

export default Homepage