import React, { useEffect } from 'react';
import '../App.css';

const MapAPI = ({ center, source }) => {
  useEffect(() => {
    function initMap() {
        const map = new window.google.maps.Map(document.getElementById('map'), {
            mapId: "55a0a8bd8e0265c6",
            center: center,
            zoom: 16,
            zoomControl: false,
            fullscreenControl: false, 
            streetViewControl: false,
            mapTypeControl: false,
            rotateControl: false
        });

        const customMarkerIcon = {
          url: "../icons/marker.svg",
          scaledSize: new window.google.maps.Size(96, 96), 
        };

        const marker = new window.google.maps.Marker({
            position: center,
            map: map,
            icon: customMarkerIcon
        });

        console.log(marker)

        marker.addListener('click', () => {
            const googleMapsUrl = source;
            window.open(googleMapsUrl, '_blank');
        });
    }

    // Make sure the Google Maps API is loaded before calling initMap
    if (window.google && window.google.maps) {
      initMap();
    } else {
      // If the API is not loaded yet, wait for it to load
      window.initMap = initMap;
    }
  }, [center, source]);

  return <div id="map" style={{ height: '400px', border: `solid 4px #0a3f2e` }}></div>;
};

export default MapAPI