import { NavLink as RouterLink } from "react-router-dom";

function HeroText(){
    const shiftLeft = {
        textAlign: `right`
    }

    const alignRight = {
        textAlign: `right`
    }

    return(
        <div className="col-lg-4 hero-text">
            <div className="row justify-content-end">
                <h1 className="daruma-text" style={alignRight}>Welcome to my ramen bible</h1>
                <p style={alignRight}>What is that one food that you will never get tired of eating? The food that you can still enjoy even when you are sick, and the first thing that comes to mind when you feel hungry at an ungodly hour? Mine is ramen - probably 85% of the time - it's ramen, at least. I strive to eat all of the yummiest ramen out there and try all of the most unique ramen available. join me in my attempt to document some of the most noteworthy ones from my slurpy journey!</p>
                <p style={alignRight}>In the name of the TARE, the SALT and the holy ABURA</p>
                <RouterLink to="/collection" style={shiftLeft}><AccentButton text="RAMEN"/></RouterLink>
            </div>
        </div>
    )
}

function AccentButton(props){
    return(
        <button className="accent-button">{props.text}</button>
    )
}

export default HeroText