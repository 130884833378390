import Heroimage from "./Heroimage";
import HeroText from "./Herotext";
import RamenCard from "./Ramencard";
import Contact from "./Contact";
import Table from "./Table";

function Section(props){
    const divStyle = {
        backgroundImage: `url(${props.bgimage})`,
        backgroundRepeat: `repeat-y`,
        
    };

    const topDivStyle = {
        backgroundImage: `url(${props.bgimage})`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center bottom`,
    }

    const bottomDivStyle = {
        backgroundImage: `url("/images/bg_bottom.png"), url("/images/bg_mid.png")`,
        backgroundRepeat: `no-repeat, repeat-y`,
        backgroundPosition: `center bottom, center top`
    }

    const boxStyle = {
        width: `100%`,
        marginLeft: `0px`,
        marginRight: `0px`,
        paddingLeft: `10%`,
        paddingRight: `10%`
    }

    const mainTextStyle = {
        height: `fit-content`, 
        textAlign: `left`,
        marginTop: `80px`,
        marginLeft: `10%`,
        marginRight: `10%`,
        border: `solid 4px #0a3f2e`,
        backgroundColor: `#efe7d0`
    }

    let content, specificStyle
    const section0 = <section className="container-fluid" id="home" style={{height: `100%`}}>
                        <div className="row" style={{height: `100%`}}>
                            <div className="col-lg-3"></div>
                            <HeroText />
                            <Heroimage />
                        </div>
                    </section>
    
    const section1 = <section className="container-fluid" id="ramen-table" style={{height: `100%`}}>
                        <div className="row" style={mainTextStyle}>
                            <p style={{padding: `16px`, margin: `0px`}}>A great bowl of ramen consist of 5 elements. The noodle, broth and toppings on the surface level, and the tare (secret sauce) and abura (aroma oils) that add layers of flavors to the otherwise humble bowl. Some of the typical stuff are listed below, but the options are surely not limited to this - for ramen has limitless possiblities!!</p>
                        </div>
                        <div className="row scrollable" style={{marginTop: `48px`, marginLeft: `10%`, marginRight: `10%`, overflowX: `scroll`}}>
                            <Table />
                        </div>
                    </section>
    
    const section2 = <section className="container-fluid" id="reco" style={{minHeight: `100%`}}>
                        <div className="row" style={{height:`4%`, paddingTop: `40px`}}>
                            <h1 className="daruma-text">お す す め</h1>
                        </div>
                        <div className="row" style={{height:`32px`}}>
                        </div>
                        <div className="row justify-content-center" style={boxStyle}>
                            <RamenCard destination="0" ramenimage={"/images/rishiri.jpg"} />
                            <RamenCard destination="2" ramenimage={"/images/spicy.jpg"} />
                            <RamenCard destination="4" ramenimage={"/images/gogyo.jpg"} />
                        </div>
                        <div className="row justify-content-center" style={boxStyle}>
                            <RamenCard destination="5" ramenimage={"/images/tequila.jpg"} />
                            <RamenCard destination="12" ramenimage={"/images/pineapple.jpg"} />
                            <RamenCard destination="19" ramenimage={"/images/anchovy.jpg"} />
                        </div>
                    </section>
    
    const section3 = <section className="container-fluid row justify-content-center" id="contact" style={{height: `100vh`}}>
                        <Contact />
                    </section>
    
    if (props.sequence === "0"){
        content = section0
        specificStyle = topDivStyle
    } else if (props.sequence === "1"){
        content = section1
        specificStyle = divStyle
    } else if (props.sequence === "2"){
        content = section2
        specificStyle = divStyle
    } else if (props.sequence === "3"){
        content = section3
        specificStyle = bottomDivStyle
    }

    return(
        <div id={props.id} className="section" style={specificStyle}>
            {content}
        </div>
    )
}

export default Section