function Heroimage(){
    const heroImageStyle = {
        backgroundImage: `url(/images/rAmen.jpg)`,
    }
    
    return(
        <div className="col-lg-5 hero-image-container">
            <div className="row hero-image" style={heroImageStyle}></div>
        </div>
    )
}

export default Heroimage