import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Contact(){
    return(
        <div className="col-lg-4 align-self-end contact">
            <h3 className='daruma-text coloured-child' style={{marginBottom: `0px`, paddingBottom: `0.5rem`}}>Wanna get some ramen together?</h3>
            <p className='coloured-child' style={{marginBottom: `0px`, paddingBottom: `0.5rem`}}>Or have some ramen recommendation that Anna absolutely has to try? Or *wink wink* wanna enquire about this cool website??</p>
            <div className="d-flex justify-content-center coloured-child" style={{marginBottom: `0px`, paddingBottom: `0.5rem`, paddingLeft: `60px`, paddingRight: `60px`}}>
                <FontAwesomeIcon icon={faEnvelope} style={{color: "#efe7d0", height: `30px`, width:`30px`, paddingRight: `30px`}} />
                <FontAwesomeIcon icon={faLinkedin} style={{color: "#efe7d0", height: `30px`, paddingRight: `30px`}}/>
                <FontAwesomeIcon icon={faInstagram} style={{color: "#efe7d0", height: `30px`}}/>
            </div>
        </div>
    )
}

export default Contact