import { Link } from 'react-router-dom';

function RamenCard(props){
    const destinationRoute = `/content/${props.destination}`;

    const cardStyle = {
        backgroundImage: `url(${props.ramenimage})`,
    }

    return(
        <Link to={destinationRoute} className="col-lg-4 card-container">
            <div className='ramen-card' style={cardStyle}></div>
        </Link>
    )
}

export default RamenCard