import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ramenData } from "./Database"

function ContentIndex(){
    const [locationFilter, setLocationFilter] = useState('all');
    const [noodleFilter, setNoodleFilter] = useState('all');
    const [soupFilter, setSoupFilter] = useState('all');
    const [result, setResult] = useState(Object.keys(ramenData));

    const handleLocationChange = (event) => {
        setLocationFilter(event.target.value);
    };

    const handleNoodleChange = (event) => {
        setNoodleFilter(event.target.value);
    };

    const handleSoupChange = (event) => {
        setSoupFilter(event.target.value);
    };

    const filterResults = () => {
        const filteredResults = []
        for (let i = 0; i < Object.keys(ramenData).length; i++) {
            if (locationFilter === 'all' || ramenData[i].city === locationFilter){
                if (noodleFilter === 'all' || ramenData[i].ramenNoodle === noodleFilter){
                    if (soupFilter === 'all' || ramenData[i].ramenBase === soupFilter){
                        filteredResults.push(i)
                    }
                }
            }
        }
        setResult(filteredResults);
    };
    
    const componentsArray = []
    for (let i = 0; i < result.length / 3; i++) {
        let imageArr = []
        let textArr = []
        let keys = []
        for (let j=0; j<3; j++){
            if (ramenData[result[i * 3 + j]]){
                imageArr.push("/images/" + ramenData[result[i * 3 + j]].primaryImg + ".jpg")
                textArr.push(ramenData[result[i * 3 + j]].shopName)
                keys.push(ramenData[result[i * 3 + j]].key)
            }
        }
        componentsArray.push(<CardRow images={imageArr} text={textArr} keys={keys} />);
    }
    if (componentsArray.length === 0){
        componentsArray.push(<h3>No such entry can be found in the bible, sorry... Anna will eat more ramen, do come back to check for updates</h3>)
    }
    
    return(
        <div className="container-fluid" style={{height: `100%`}}>
            <div className='container-fluid' style={{marginTop: `72px`, paddingLeft: `10%`, paddingRight: `10%`}}>
                <div className='row' style={{marginBottom: `24px`}}>
                    <h1 className='daruma-text'>What kind of ramen do you fancy ??</h1>
                </div>
                <div className='row'>
                    <Filter 
                        label={"Location: "}
                        options={["Tokyo", "Osaka", "Yokohama", "Singapore", "Stuttgart"]}
                        selectedFilter={locationFilter}
                        onFilterChange={handleLocationChange}/>
                    <Filter 
                        label={"Noodle Type: "}
                        options={["Straight", "Wavy", "Curly"]}
                        selectedFilter={noodleFilter}
                        onFilterChange={handleNoodleChange}/>
                    <Filter 
                        label={"Ramen Base: "}
                        options={["Shoyu", "Miso", "Tonkotsu", "Shio"]}
                        selectedFilter={soupFilter}
                        onFilterChange={handleSoupChange}/>
                    <div className='col-lg-3 sol-sm-12'>
                        <button className='filter-button' onClick={filterResults}>APPLY  FILTER</button>
                    </div>
                </div>
            </div>
            <div className="row" style={{height:`5vh`}}></div>
            {componentsArray}
            <div className="row" style={{height:`10vh`}}></div>
        </div>
    )
}

function Filter(props){
    return(
        <div className='col-lg-3 col-sm-12' style={{height: `fit-content`, marginBottom: `12px`, display:`flex`}}>
            <label style={{marginRight: `12px`, flex:`2`, textAlign: `left`}}>{props.label}</label>
            <select value={props.selectedFilter} onChange={props.onFilterChange}>
                <option value="all">All</option>
                {props.options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    )
}

function CardRow(props){
    const boxStyle = {
        paddingLeft: `10%`,
        paddingRight: `10%`
    }
    
    const componentsArray = [];
    for (let i=0; i<props.images.length; i++){
        componentsArray.push(
            <div className="col-lg-4 col-sm-12" style={{height: `fit-content`, marginBottom: `32px`}}>
                <RamenCard destination={props.keys[i]} ramenimage={props.images[i]} style={{width: `100%`}}/>
                <CardTitle text={props.text[i]} />
            </div>
        )
    }

    return (
        <div className="row justify-content-center" style={boxStyle}>
            {componentsArray}
        </div>
    )
}

function CardTitle(props){
    return (
        <div className="row" style={{padding: `0px`, margin: `0px`, marginTop: `24px`, textAlign: `center`}}>
            <p>{props.text}</p>
        </div>
    )
}

function RamenCard(props){
    const destinationRoute = `/content/${props.destination}`;

    const cardStyle = {
        backgroundImage: `url(${props.ramenimage})`,
    }

    return(
        <Link to={destinationRoute} className="row card-container" style={{width: `auto`}}>
            <div className='ramen-card' style={cardStyle}></div>
        </Link>
    )
}

export default ContentIndex