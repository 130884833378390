export const ramenData = {
    0: {
        key: 0,
        dishName: "Ramen in scorched soy sauce-base soup",
        shopName: "Rishiri Ramen Miraku (Shin-Yokohama Raumen Museum)",
        visitDate: "19/05/2019",
        primaryImg: "rishiri",
        secondaryImg: null,
        city: "Yokohama",
        country: "Japan",
        mapURL: "https://goo.gl/maps/CBzHWiA33TnERNsm8",
        location: {lat: 35.5099291, lng: 139.6146256},
        ramenBase: "Shoyu",
        ramenNoodle: "Curly",
        toppings: ["chashu", "nori", "menma", "fungus", "spring"],
        shortDesc: "A sought after shoyu ramen made with special kombu from Rishiri Island",
        longDesc: "Being located on the remote Rishiri Island near Hokaido, Rishiri Ramen Miraku (miracle) is probably the hardest ramen-ya to get to. So imagine how excited I was when I found out that they have a stall in the Shin-Yokohama Raumen Museum during my stay in tokyo in 2019. I had their signature shoyu ramen, made with seaweed from Rishiri and their perfectly springy, curly noodles. I have to admit that this shop has not been overhyped at all. The shoyu-based broth was so good and rich, and pairs perfectly with their noodles. I must say this is the best ramen I have ever had, but I might be a little biassed because I am a shoyu ramen fan. It was so good that I actually went there twice to have it one last time before I had to leave Japan. Now I really wonder how great must the same bowl taste like, fresh in their original shop in Rishiri Island!"
    },
    1: {
        key: 1,
        dishName: "Muku Zweite Ramen",
        shopName: "Muku Zweite (Shin-Yokohama Raumen Museum)",
        visitDate: "19/05/2019",
        primaryImg: "german",
        secondaryImg: "koizumizweite",
        city: "Yokohama",
        country: "Japan",
        mapURL: "https://goo.gl/maps/CBzHWiA33TnERNsm8",
        location: {lat: 35.5099291, lng: 139.6146256},
        ramenBase: "Tonkotsu",
        ramenNoodle: "Straight",
        toppings: ["bacon", "sauerkraut", "pepper", "spring"],
        shortDesc: "A fusion ramen from a popular German ramen-ya",
        longDesc: "Muku Zweite is a German ramen-ya which is popular enough to be invited to open a stall in the Shin-Yokohama Raumen Museum. I had their Zweite ramen which is only available in the Raumen Museum (you can't get it in their shop in Germany) The thick tonkotsu-shoyu broth is topped with sauerkrau, thick German bacon and black pepper. Sauerkraut with ramen doesn't sound very appetizing at first, and I had second thoughts about my order, but I thought to myself that that was probably my only chance to try this super unique ramen. I'm glad to say that I didn't regret my choice (despite this being the third bowl of the day), the bowl as a whole was super delicious and unique and the sauerkraut actually gave a really refreshing sour contrast to the thick and rich broth."
    },
    2: {
        key: 2,
        dishName: "Arctic Ramen",
        shopName: "Mouko Tanmen Nakamoto",
        visitDate: "26/10/2023",
        primaryImg: "spicy",
        secondaryImg: "koizumispicy",
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/esewSSvw6Fcmz2yg7",
        location: {lat: 35.7059298, lng: 139.7743831},
        ramenBase: "Miso",
        ramenNoodle: "Wavy",
        toppings: ["chashu", "sprouts"],
        shortDesc: "The best (super) spicy ramen ever",
        longDesc: "Mouko Tanmen Nakamoto has a few branches around Tokyo which makes getting your ***hole burning ramen fix much easier. Instead of having multiple level of spiciness for a single dish, Mouko Tanmen has a unique approach where they have 1 dish for each spice level. The arctic ramen is level 9 in a scale of 10. (The level 10 dish was a tsukemen which i did try on my third visit) The noodle came doused in a threateningly thick, red soup and topped with some forgiving stash of beansprouts. The soup is as spicy as it looks but I was struggling to stop slurping as it was super tasty and addictive. As expected, I regretted the whole thing the next day when I probably almost cried in the bathroom and swore that I would not eat it again. But the trauma totally didn't last since I already mentioned - I went to eat it 2 more times."
    },
    3: {
        key: 3,
        dishName: "Oni Level Ramen",
        shopName: "Kikanbo",
        visitDate: "10/06/2019",
        primaryImg: "oni",
        secondaryImg: null,
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/crnbYYRUSU49HmN98",
        location: {lat: 35.7059086, lng: 139.73063},
        ramenBase: "Miso",
        ramenNoodle: "Straight",
        toppings: ["chashu", "egg", "corn", "pepper", "spring"],
        shortDesc: "A popular 'devil' ramen in Tokyo",
        longDesc: "Kikanbo is very popular in Tokyo for their spicy ramen, but not to worry, their ramen comes in multiple spice level so even people who can't take too much heat can enjoy a bowl too. Being 'oni' themed, they are most famous for their 'oni'-level ramen, which is the spiciest level they have. Naturally, I went for the oni-level. The noodles came in a vibrant red soup and covered in an overly generous layer of togarashi powder. The soup was good and has a good kick, but surprisingly tolerable enough heat for something that is claimed to have come from hell. They also gave you a cute piece of oni shaped candy to help calm your tongue down if you have ordered the oni level."
    },
    4: {
        key: 4,
        dishName: "Burnt Miso Ramen",
        shopName: "Nishiazabu Gogyo",
        visitDate: "31/07/2019",
        primaryImg: "gogyo",
        secondaryImg: null,
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/75wpUxqNHvfPZcpw7",
        location: {lat: 35.7054503, lng: 139.4414147},
        ramenBase: "Miso",
        ramenNoodle: "Straight",
        toppings: ["chashu", "nori", "egg", "naruto"],
        shortDesc: "A unique and rich bowl of ramen made with burnt miso",
        longDesc: "The burnt kogashi ramen at Gogyo ramen is made with a miso and soy sauce based broth cooked in lard in high heat. This process gives a unique and deep aroma, making the broth totally irresistible. The noodle is served to you in a dark coloured soup, covered in floating specks of burnt miso parcticles and a super alluring, smokey aroma. The rich, heavy and utterly flavourful broth is super memorable. I would really love to come back for a second time if not for the long queue."
    },
    5: {
        key: 5,
        dishName: "Tequila Ramen",
        shopName: "Menya Kawano",
        visitDate: "04/07/2019",
        primaryImg: "tequila",
        secondaryImg: "koizumir20",
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/vDfzmc1erAA545uz9",
        location: {lat: 35.7711542, lng: 139.6419371},
        ramenBase: "Shio",
        ramenNoodle: "Curly",
        toppings: ["chashu", "pepper", "cilantro"],
        shortDesc: "A mexican fusion ramen with a dose of tequila",
        longDesc: "Tequila in ramen might or might not sound like a good idea, but I definetely had to give it a try. Menya Kono is located pretty far from Tokyo's city center, so it was not the most convenient shop to go to, however I just had to try this alcoholic ramen. The very generous pile of corriander leaves was a good contrast against the clear broth and pinkish chashu. The soup was clean and pretty unique, though I must say that I couldn't exactly taste the tequila."
    },
    6: {
        key: 6,
        dishName: "Seabura Ramen",
        shopName: "Ramen Hirataishu Ajian Gotanda",
        visitDate: "20/06/2019",
        primaryImg: "seabura",
        secondaryImg: "koizumiseabura",
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/q11h6yNz7JneCVmD6",
        location: {lat: 35.6224896, lng: 139.7231735},
        ramenBase: "Tonkotsu",
        ramenNoodle: "Straight",
        toppings: ["chashu", "spring", "fat"],
        shortDesc: "A bowl of ramen covered in a blanket of pork back fat",
        longDesc: "You can barely see any noodles or even any toppings in this bowl, and I didn't even top up for extra pork fat. The tonkotsu soup and topping was good but not too special, however the snowy blanket of sieved pork fat on the soup really elevated the richness of this whole bowl. I don't think I have ever had that much fat in a single meal in my entire exsitence."
    },
    7: {
        key: 7,
        dishName: "Rich Soy Sauce Ramen",
        shopName: "KaneKitchen Noodles",
        visitDate: "12/07/2019",
        primaryImg: "kanekitchen",
        secondaryImg: null,
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/951LnBWBDqjminQf6",
        location: {lat: 35.7290311, lng: 139.6827286},
        ramenBase: "Shoyu",
        ramenNoodle: "Straight",
        toppings: ["chashu", "menma", "egg", "onion"],
        shortDesc: "A clean and minimalist shoyu ramen that has made itself into the Michellin guide",
        longDesc: "This humble looking shop was tucked away near a station in a rather suburban area of Tokyo near Ikkebukuro. The ramen came in a clear shoyu broth with a whole uncut egg and rectangular pieces of onion floating on it. This ramen has a very clean and light taste. Overall, this ramen tasted pretty westernised and although it was pretty good, I don't know how it made it to the michellin guide."
    },
    8: {
        key: 8,
        dishName: "Rich Pork Bone Ramen",
        shopName: "Menya Bushido",
        visitDate: "27/072019",
        primaryImg: "koiwa",
        secondaryImg: null,
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/sMWsu6d8VwUUvbjW8",
        location: {lat: 35.7371896, lng: 139.8743178},
        ramenBase: "Tonkotsu",
        ramenNoodle: "Straight",
        toppings: ["chashu", "nori", "egg", "vegetable", "naruto"],
        shortDesc: "Tonkotsu ramen with a lot of vegetables and charred chashu",
        longDesc: "Menya Bushido in Koiwa has a pretty small shop but seriously big portion for their ramen. The thick noodles pairs well with the thick tonkotsu X seafood broth. Overall this bowl of ramen had a good texture that I had to try their tsukemen too."
    },
    9: {
        key: 9,
        dishName: "Shio Ramen",
        shopName: "Menya Kintoki",
        visitDate: "28/07/2019",
        primaryImg: "chicken",
        secondaryImg: null,
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/16TENecgGbGgsDgs9",
        location: {lat: 35.7404021, lng: 139.6773734},
        ramenBase: "Shio",
        ramenNoodle: "Straight",
        toppings: ["chashu", "egg", "menma", "spring"],
        shortDesc: "A simple, award-winning chicken broth ramen",
        longDesc: "Menya Kintoki has a small 8 seater shop, tucked away in a residential area in suburban Tokyo. Despite the humble shop and location, their chicken broth ramen has made it to the Michellin guide. The clear soup was very transparent and pristine, you can clearly see the very neatly laid out noodle at the bottom of the bowl and the minimalist toppings of bamboo shoots, egg, chashu and chives, elegantly arranged on top. This ramen shop apparently has a cult following, and although I can't say that I'm joining, I must say that the flavour is as elegant as it looks. The broth was light and clean but richer than you would expect from something this clear and transparent. It was a beautiful bowl."
    },
    10: {
        key: 10,
        dishName: "Gyokai Tonkotsu Tokyo-eki Ramen",
        shopName: "Tokyoeki Ikaruga",
        visitDate: "09/07/2019",
        primaryImg: "tokyoeki",
        secondaryImg: null,
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/w6WgK2QpxmtdptPs5",
        location: {lat: 35.6804661, lng: 139.7679735},
        ramenBase: "Tonkotsu",
        ramenNoodle: "Straight",
        toppings: ["chashu", "nori", "menma", "spring", "naruto"],
        shortDesc: "A seafood tonkotsu ramen in Tokyo station's ramen street",
        longDesc: "Like any other ramen shop at the ramen street, you need to queue to get into Ikaruga. Their thick tonkotsu soup clings to every strand of their springy thin noodles giving you a burst of flavour in every bite. Their standard, egg, chashu and bamboo shoot topping might not be the most exciting, but did not disappoint."
    },
    11: {
        key: 11,
        dishName: "Pork Rib Ramen",
        shopName: "Hanamaruken Namba Hozenji",
        visitDate: "07/07/2019",
        primaryImg: "osakarib",
        secondaryImg: null,
        city: "Osaka",
        country: "Japan",
        mapURL: "https://goo.gl/maps/X1BGyTJdf4RhRT9m7",
        location: {lat: 34.6679045, lng: 135.5030584},
        ramenBase: "Tonkotsu",
        ramenNoodle: "Straight",
        toppings: ["ribs", "nori", "menma", "spring", "naruto"],
        shortDesc: "A popular ramen in Osaka with chunks of pork ribs",
        longDesc: "The tonkotsu soup was really rich and the chunks of ribs was a refreshing change from the usual slices of chashu. The ribs were meaty and soft and pairs well with the springy noodles. The portion was pretty big too."
    },
    12: {
        key: 12,
        dishName: "Pineapple Ramen",
        shopName: "Papapapapine",
        visitDate: "01/08/2019",
        primaryImg: "pineapple",
        secondaryImg: "koizumipine",
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/C7dWuD4u858oJaWq6",
        location: {lat: 35.5402344, lng: 139.449636},
        ramenBase: "Shio",
        ramenNoodle: "Straight",
        toppings: ["chashu", "pineapple", "vegetable", "pepper", "spring"],
        shortDesc: "Fruity shio ramen in a pineapple themed shop",
        longDesc: "Initially I went to visit this shop just because it has a cute concept , but I was pleasantly surprised to find that the ramen was very yummy. Papapapapine's shop is very obviously pineapply, with pineapple related decorations and yellow accents all over the shop. Their signature dish is unsurprisingly - pineapple shio ramen. The shio broth is infused with pineapple juice and shrimp, and you can see a couple pieces of pineapple floating in your soup. During my visit, they had a limited time melon ramen too, so I had to give that a try as well, and while it was good, the pineapple soup was a very clear winner."
    },
    13: {
        key: 13,
        dishName: "Jiro-style Ramen",
        shopName: "Ramen Butayama",
        visitDate: "30/07/2019",
        primaryImg: "jirostyle",
        secondaryImg: null,
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/bMCXvs6FKPTc6oci7",
        location: {lat: 35.7296407, lng: 139.6830605},
        ramenBase: "Tonkotsu",
        ramenNoodle: "Wavy",
        toppings: ["chashu", "garlic", "vegetable", "sprouts"],
        shortDesc: "A neighbourhood ramen with a mountain of vegetables",
        longDesc: "Butayama is not a famous shop, it was just a shop which is very close from my house during my stay in Tokyo. Despite that, the shop is almost always full with people from around the neighbourhood. The shop offers jiro-style ramen which means that the bowl is served with a mountain of vegetables (cabbage and sprouts) and thick slices of chashu. This particular bowl also came with a huge spoon of minced garlic. There was so much vegetables that it actually took awhile to get to the noodles, you would think that this might be a healthy meal. The tonkotsu broth was very rich and flavorful, the huge amount of vegetable actually did a really great job balancing it and giving the bowl a great texture. I came out super full and satisfied."
    },
    14: {
        key: 14,
        dishName: "Kara Niku Ramen",
        shopName: "Keira Ramen Shinjuku Fandien",
        visitDate: "23/06/2019",
        primaryImg: "mangakisa",
        secondaryImg: null,
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/CC4aqCmTbfUCxzEs7",
        location: {lat: 35.6930943, lng: 139.6998135},
        ramenBase: "Tonkotsu",
        ramenNoodle: "Straight",
        toppings: ["chashu", "egg", "menma", "vegetable", "spring"],
        shortDesc: "Late night ramen in Shinjuku",
        longDesc: "Have you ever missed the last train home? Well I had, and looks like many people regularly do in Shinjuku as there are so many things you can do while waiting for the first train in the morning. Well, you can go to a mangakissa, have some beer and yakitori in a bar or get handsy in a karaoke room. But is there anything better than a warm, hearthy bowl of ramen in a quiet corner of Shinjuku? This flavorful bowl woke me right up at 4am in the morning."
    },
    15: {
        key: 15,
        dishName: "Special Rich Niboshi Ramen",
        shopName: "Tokyo Niboshi Ramen Gyoku",
        visitDate: "28/05/2019",
        primaryImg: "anchovy",
        secondaryImg: null,
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://goo.gl/maps/a1xje7do3n3wU9JGA",
        location: {lat: 35.6800611, lng: 139.7679649},
        ramenBase: "Shoyu",
        ramenNoodle: "Straight",
        toppings: ["chashu", "egg", "menma", "bonito", "spring"],
        shortDesc: "A lovely bowl of niboshi ramen in Tokyo station",
        longDesc: "Ramen Gyoku's shop is part of the impressive array of ramen shops tucked in Tokyo Station's ramen street. Niboshi ramen is in a way unique because the broth is made with simmering anchovy flakes with shio or shoyu based broth. The bowl I had in Gyoku was made with shoyu X niboshi. It has a light but deep flavour which is pretty addictive."
    },
    16: {
        key: 16,
        dishName: "Dan Dan Noodles",
        shopName: "Enishi",
        visitDate: "16/06/2023",
        primaryImg: "sgenishi",
        secondaryImg: null,
        city: "Singapore",
        country: "Singapore",
        mapURL: "https://goo.gl/maps/v4teaquNLsnwPYcw5",
        location: {lat: 1.2756998, lng: 103.8432295},
        ramenBase: "Shio",
        ramenNoodle: "Straight",
        toppings: ["chashu", "egg", "onion", "pickle", "spring"],
        shortDesc: "A small autenthic ramen shop in a shopping center in the CBD",
        longDesc: "Enishi is a small ramen shop hidden in a corner of a shopping center in Singapore's CBD. The L-shaped counter gives of a really authentic Japanese touch to the less than 10 seater shop. Their signature menu is their dan dan noodles which is served and eaten like mazesoba with a lot of colourful topping. They even have a pretty wide selection of vinegar that you can add to your mazeramen (yuzu vinegar, chilli vinegar etc). Other than the dan dan noodles, they also serve traditional ramen. Their shio ramen was expecially good. You can also request for a small serving of rice to finish off your ramen broth here! Overall, Enishi is probably one of the more authentic ramen shop in Singapore. Given that it is also not part of any ramen franchise, Enishi might just be the best ramen shop in Singapore to date."
    },
    17: {
        key: 17,
        dishName: "Sakura Ebi Ramen",
        shopName: "Ramen Bar Suzuki",
        visitDate: "30/03/2019",
        primaryImg: "sgsuzuki",
        secondaryImg: null,
        city: "Singapore",
        country: "Singapore",
        mapURL: "https://goo.gl/maps/iePBVczhr726u3v89",
        location: {lat: 1.2862428, lng: 103.8492092},
        ramenBase: "Shoyu",
        ramenNoodle: "Straight",
        toppings: ["chashu", "egg", "pickle", "shrimp", "spring"],
        shortDesc: "A quaint ramen-ya at the fringe of Singapore's night life scene",
        longDesc: "Ramen Suzuki offered some of the more interesting menu options back then. They served limited bowls of pink sakura ebi ramen, green basil parmesan ramen and red tomato ramen. Despite sounding gimmicky, I was pretty addicted to their sakura ebi ramen. It was the best bowl of ramen I could find then. The shop also offered a free flow side dishes table which included boiled egg, potato salad and wakame. This sadly stopped during covid when they also redid their menu and removed all of the special ramens which in my opinion was their selling point. Shame."
    },
    18: {
        key: 18,
        dishName: "Menbaka Original Fire Ramen",
        shopName: "Fire Ramen & Izakaya by Menbaka",
        visitDate: "29/06/2021",
        primaryImg: "sgmenbaka",
        secondaryImg: null,
        city: "Singapore",
        country: "Singapore",
        mapURL: "https://goo.gl/maps/LEtYBuBrgrJmr9Ct7",
        location: {lat: 1.3019805, lng: 103.8348294},
        ramenBase: "Shoyu",
        ramenNoodle: "Wavy",
        toppings: ["chashu", "egg", "fungus", "nori", "spring"],
        shortDesc: "A flaming bowl of shoyu ramen from Kyoto",
        longDesc: "The sound of flame acompanied by a male voice shouting 'FIRE!' can be heard frequently from this shop. The fire show is definitely impressive for first timers. They would even hang your phone from the kitchen to help you get a good shot of your experience. Initially I thought that the fire element was all gimmicks, but I was really surprised to find that the ramen was super yummy. The charred aroma from the fire has been absorbed by the whole bowl of ramen alongside their green onion oil. A mild warning tho - your mouth will smell like green onion for the rest of the day."
    },
    19: {
        key: 19,
        dishName: "Singapore Style Ramen",
        shopName: "A Noodle Story",
        visitDate: "24/06/2021",
        primaryImg: "sgstory",
        secondaryImg: null,
        city: "Singapore",
        country: "Singapore",
        mapURL: "https://goo.gl/maps/SQwiTmkjwmPn8Kja9",
        location: {lat: 1.2792238, lng: 103.8454294},
        ramenBase: "Shio",
        ramenNoodle: "Curly",
        toppings: ["chashu", "egg", "fungus", "spring", "shrimp", "wanton"],
        shortDesc: "A Singaporean fusion ramen!",
        longDesc: "This 'Singporean ramen' was born in a hawker center stall in Singapore. The noodles used here does not really count as ramen noodles, it's a lot more like mee kia - a very thin and curly egg noodle. They incorporated a lot of local flavours to this, including the sambal chilli and chinese style charsiu and also the chicken broth on the side. If you are a ramen purist, this is definitely not for you, but this is something homebrewed in Singapore, completely unique and refreshing. And as a bonus, it is super yummy too! Also, the noodle wrapped fried shrimp is definitely to die for."
    },
    20: {
        key: 20,
        dishName: "Tonkotsu Ramen",
        shopName: "Hakata Ikkousha",
        visitDate: "16/02/2019",
        primaryImg: "sghakata",
        secondaryImg: null,
        city: "Singapore",
        country: "Singapore",
        mapURL: "https://goo.gl/maps/PQqN8cqS6rAyVQmx7",
        location: {lat: 1.2771166, lng: 103.8406739},
        ramenBase: "Tonkotsu",
        ramenNoodle: "Straight",
        toppings: ["chashu", "egg", "fungus", "spring"],
        shortDesc: "A tonkotsu ramen specialist under an HDB",
        longDesc: "Hakata Ikkousha is a ramen chain from Japan which has ventured to the south east asian market, and although they serve delicious tonkotsu ramen, nothing seems to stand out too much from this shop. There is one thing though. One thing that keeps people coming at least once a year. If you come on your birthday, you are entitled to free gyozas as many as your age! They did cap it at 50 (otherwise this shop might be filled with grandpas and grandmas probably), but yea - what are you waiting for? Start celebrating birthdays here! Especially your parents, your aunts and uncles! Bring em older people here for the gyoza! Your baby brother can go eat McD on his birthday."
    },
    21: {
        key: 21,
        dishName: "Tsukemen Singapore Best",
        shopName: "Torasho Ramen & Charcoal Bar",
        visitDate: "23/08/2023",
        primaryImg: "sgtorasho",
        secondaryImg: null,
        city: "Singapore",
        country: "Singapore",
        mapURL: "https://goo.gl/maps/aATSypesbfiajddUA",
        location: {lat: 1.2786937, lng: 103.8416777},
        ramenBase: "Tonkotsu",
        ramenNoodle: "Straight",
        toppings: ["chashu", "menma", "nori", "spring", "naruto"],
        shortDesc: "A stylish bar with a wide selection of ramen",
        longDesc: "I was craving for some tsukemen and that's how I found this place - or this place found me idk. This shop offers soup ramen, dry ramen and tsukemen along with many many bar snack items and many many drinks. The thick tsukemen noodles were cooked to perfection and the dipping broth was great. What really stood out though, was the thick slide of really tender and flavourlful chashu."
    },
    22: {
        key: 22,
        dishName: "Tokusei Tsukemen",
        shopName: "Rokurinsha",
        visitDate: "23/10/2023",
        primaryImg: "rokurinsha",
        secondaryImg: null,
        city: "Tokyo",
        country: "Japan",
        mapURL: "https://maps.app.goo.gl/xFHtpZk2Dzwxp1F76",
        location: {lat: 35.6800592, lng: 139.7678465},
        ramenBase: "Tonkotsu",
        ramenNoodle: "Wavy",
        toppings: ["chashu", "menma", "egg", "nori", "spring", "naruto", "chili"],
        shortDesc: "A busy ramen-ya in the basement of the Tokyo Station",
        longDesc: "They say this is the best tsukemen shop in Tokyo and so far - it indeed is! The super thick noodles cooked to perfection has a very addictive, chewy texture. The broth is amazing too, it's thick but not too rich, it really helps the noodle slide down your throat easily. The portion is huge, so don't be cocky and order extra noodles. The normal portion is already huge - you've been warned. The Tokyo station shop is super popular, so if you go just 15 mins after their lunch time opening hours, be prepared to queue anywhere between 30 -90 mins. A pro tip is that they are strangely open in the early morning for breakfast. Who eats tsukemen for breakfast you ask? me. ME. ok. Anyway, at 8am, there was barely anyone visiting the shop, no queue whatsoever. They adjust the breakfast broth to be somewhat lighter, which is to my liking."
    },
    23: {
        key: 23,
        dishName: "Roast Beef Maze Men",
        shopName: "Suzuna",
        visitDate: "03/10/2023",
        primaryImg: "suzuna",
        secondaryImg: null,
        city: "Stuttgart",
        country: "Germany",
        mapURL: "https://maps.app.goo.gl/LkMwUtNv4SzZkyvc7",
        location: {lat: 48.7715746, lng: 9.1759699},
        ramenBase: "Shoyu",
        ramenNoodle: "Straight",
        toppings: ["chashu", "menma", "nori", "spring"],
        shortDesc: "A quaint izakaya style shop at the fringe of the city center",
        longDesc: "When you go to Europe, you probably wouldn't think of ramen. But hey - Germnay probably have some of the best ramens in Europe, one of their shops even got invited to open a stall at the Yokohama raumen museum (see my Muku Zweite entry)! Anyway I was in Stuttgart for work and a friend who lives there invited me to dinner at Suzuna, so of course I have to order their ra.. what? roast beef ramen??? So of course I had to try this seemingly fusion bowl of goodness and it did not disappoint. The noodle is understandably inferior to the stuff you get in Asia, but the pairing with roast beef was surprisingly good! This bowl was a bit more like a maze soba than traditional ramen and you can judge if this is a good thing or not, but the roast beef was definitely the star of this dish."
    }
}